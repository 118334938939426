import React from 'react';
import { Heading, Box, Center, useDisclosure, Button } from '@chakra-ui/react';
import { Timeline, TimelineEvent } from 'react-event-timeline';

import useInvitation from '@hooks/useInvitation';

import WithAnimation from '@components/Common/WithAnimation';
import AddToCalendarButton from '@components/Common/AddToCalendar';
import CountDown from '@components/Common/CountDown';
import QRSection from '@/components/Common/QRCard/V2';
import WeddingDetail from './WeddingDetail';
import BaliGuestInformation from './BaliGuestInformation';

import {
  // WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  // WEDDING_RESEPSI_FULLDATE,
  GOOGLE_MAPS_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_ROAD,
  // WEDDING_RESEPSI_LOC_NAME,
  // WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { HEADING_STYLES, LINK_STYLES, PRIMARY_FONT } from '@constants/colors';
import { ID_COUNTDOWN_SECTION } from '@constants/identifier';
import * as ID from '@/constants/identifier';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
// import useShiftTime from '@hooks/useShiftTime';

function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const lang = useLang();
  // const time = useShiftTime();
  const eventTimeline = [
    {
      img: '',
      title: `Thursday, 21 March 2024`,
      description: `Depart from Perth CBD to Margaret River <br/>(3.5 hours drive by Bus, transportation provided)`,
    },
    {
      img: '',
      title: `Friday, 22 March 2024`,
      description: `Holy Matrimony and Reception dinner at Aravina Estate <br/>
      (Pickup & drop off from accomodation is provided)`,
    },
    {
      img: '',
      title: `Saturday, 23 March 2024`,
      description: `Depart from Margaret River to Perth CBD<br/>
      Back to Jakarta or extend your stay to  your liking`,
    },
  ];

  return (
    <>
      <Box padding="12px 0 0px 0" bgColor="bgPrimary" {...rest}>
        <WithAnimation>
          <Heading marginBottom="42px" {...HEADING_STYLES} paddingLeft="0px" textAlign="center">
            {txtWording.title[lang]}
          </Heading>
        </WithAnimation>
        {/* Wedding info section */}
        <Box padding="0 28px" marginTop="-16px">
          <WithAnimation>
            <WeddingDetail
              date={'Friday, 22nd March 2024'}
              time={WEDDING_AKAD_TIME}
              title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
              subTitle={
                isInvitation ? (
                  <>
                    <span>{WEDDING_AKAD_LOC_NAME}</span>
                    <br />
                    <span>
                      <i>{WEDDING_AKAD_LOC_ROAD}</i>
                    </span>
                    <br />
                    <span>{'Dresscode: Cocktail (Black & White)'}</span>
                  </>
                ) : (
                  <></>
                )
              }
              location={isInvitation && GOOGLE_MAPS_LINK}
            />
          </WithAnimation>
          {isInvitation && (
            <Box>
              <Heading
                textTransform="uppercase"
                fontSize="lg"
                letterSpacing="4px"
                padding="16px 0 8px"
              >
                Event Timeline
              </Heading>
              <Timeline
                lineColor="#9E9687"
                lineStyle={{ top: '20px', height: 'calc(100% - 60px)', left: '8px' }}
              >
                {eventTimeline.map((item, index) => {
                  return (
                    <WithAnimation key={`index-${index}`}>
                      <TimelineEvent
                        title={item.title}
                        titleStyle={{
                          color: '#352023',
                          fontWeight: 'normal',
                          paddingLeft: '0',
                          fontSize: '16px',
                          fontFamily: `'Noto Serif Display', serif`,
                        }}
                        subtitleStyle={{ color: '#5f5a51', fontFamily: PRIMARY_FONT }}
                        cardHeaderStyle={{ color: 'white' }}
                        // icon={false && renderIcon(item.img)}
                        // iconStyle={{ width: '50px', height: '50px' }}
                        bubbleStyle={{
                          width: '16px',
                          height: '16px',
                          border: 'none',
                          backgroundColor: '#9E9687',
                        }}
                        contentStyle={{
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          padding: '0',
                          color: '#5f5a51',
                          fontSize: '16px',
                          fontWeight: '400',
                          paddingLeft: '0',
                          marginTop: '8px',
                        }}
                      >
                        <span dangerouslySetInnerHTML={{ __html: item.description }} />
                      </TimelineEvent>
                    </WithAnimation>
                  );
                })}
              </Timeline>
            </Box>
          )}
          {/* {isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={WEDDING_RESEPSI_FULLDATE}
                time={time}
                title={txtWording.reception[lang]}
                subTitle={
                  <>
                    <span>{WEDDING_RESEPSI_LOC_NAME}</span>
                    <br />
                    <span>
                      <i>{WEDDING_RESEPSI_LOC_ROAD}</i>
                    </span>
                  </>
                }
                location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )} */}
          {false && (
            <WithAnimation>
              <Button {...LINK_STYLES} variant="ghost" padding="0" onClick={onOpen}>
                Bali Guest Information
              </Button>
            </WithAnimation>
          )}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <QRSection name={ID.ID_QRCODE} />
              </Center>
            </WithAnimation>
          )}
        </Box>
        <WithAnimation>
          <Heading
            {...HEADING_STYLES}
            textAlign="center"
            paddingLeft="0px"
            margin="26px 0 26px 0"
            name={ID_COUNTDOWN_SECTION}
          >
            COUNTDOWN
          </Heading>
        </WithAnimation>

        <Box width="100%" bgColor="bgSecondary" padding="12px 0 32px 0" color="secondaryColorText">
          <WithAnimation>
            <Center>
              <CountDown lang="en" />
            </Center>
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendarButton
                  marginTop="16px"
                  size="sm"
                  text={txtWording.calendar[lang]}
                  variant="ghost"
                  fontSize="md"
                  colorScheme="blackAlpha"
                  color="mainColorTextLight"
                  bgColor="bgAlternative"
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
      {isInvitation && <BaliGuestInformation lang={'en'} onClose={onClose} visible={isOpen} />}
    </>
  );
}

export default React.memo(WeddingSection);
