import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';

import QRBank from '@/images/bca.jpg';
import Bank from '@invitato/helpers/dist/constants/banks';
import txtWording from './locales';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text marginTop="0" color="bgSecondary" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="sm"
            fontWeight="normal"
            bgColor="bgSecondary"
            colorScheme="blackAlpha"
            //variant="outline"
            marginTop="16px"
            fontFamily="body"
            fontStyle="italic"
            color="secondaryColorText"
            onClick={() => onCopyText(bankNumber)}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <DrawerBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            fontSize="3xl"
            color="mainColorText"
            marginTop="24px"
            textTransform="uppercase"
            fontFamily="heading"
          >
            Bank Transfer
          </Heading>
          <Text align="center" fontFamily="body" fontSize="md" margin="16px 0" color="black">
            {txtWording.modalDesc[lang]}
          </Text>
          {false && (
            <Center>
              <Image
                src={QRBank}
                borderRadius="16px"
                marginTop="8px"
                maxWidth="200px"
                boxShadow="md"
                padding="8px"
              />
            </Center>
          )}
          {renderBank(Bank.bca, 'Harison Chandra', '0699557899')}
          {renderBank(Bank.bca, 'Elisa Jonatan', '8015245821')}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
